import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'
import router from '@/router'
import { LocalStorageService } from '@/services/local-storage.service'
import { Injectable, useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ElMessage } from 'element-plus'

export interface ResponseCommon { // 公共返回类型
  code?: number
  data?: string
  msg: string
}

export class ResponseError extends Error { // 返回错误
  name = 'ResponseError'

  constructor (public responseBody: any, msg?: string) {
    super(msg)
  }
}

export interface HttpDefaultErrorHandle {
  errorCode: string | number | Array<number | string>

  handler (errorCode: number | string, msg: string): void
}

export interface CommonRequestFields {
  uid?: string;
  token?: string;
}

@Injectable()
export class HttpService {
  private axiosInstance: AxiosInstance

  private commonRequestFields: CommonRequestFields = {}

  constructor (private defaultOptions: AxiosRequestConfig = {},
    private defaultErrorHandlers: HttpDefaultErrorHandle[] = []) {
    this.axiosInstance = Axios.create(this.defaultOptions)

    this.axiosInstance.interceptors.request.use(request => { // 请求拦截
      const injector = useReflectiveInjector([LocalStorageService]);
      const localStorageService = injector.get(LocalStorageService);
      request.headers = { fstoken: localStorageService.getUserToken() }
      return request
    })

    this.axiosInstance.interceptors.response.use((response: any) => { // 返回拦截
      const body = response.data
      body.data = body.data || {}
      body.code = body.code || 0
      body.msg = body.msg || ''
      if (body.code == 0) {
        return body
      } else if (body.code === -2) { // 身份过期
        router.replace('/login')
        return Promise.reject(ElMessage.error('登录超时，请重新登录！'))
      }
      return Promise.reject(body)
    })
  }

  post<T = any> (url: string, data: any): Promise<T & { resp_common?: ResponseCommon, result?: ResponseCommon }> {
    let urlParse = '?'
    for (const i in data) {
      urlParse += `${i}=${data[i]}&`
    }
    url = url + urlParse
    return this.axiosInstance.post(url, {})
  }

  loginPost<T = any> (url: string, data: any): Promise<T & { resp_common?: ResponseCommon, result?: ResponseCommon }> {
    data = qs.stringify(data)
    return this.axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
     }
    })
  }
}
