import { NullInjector, ReflectiveInjector } from '@tanbo/vue-di-plugin'

import router, { routerInjectToken } from '@/router'
import { errorCodeList, errorCodeMap, errorKeyMap } from '@/common/error/error-code'
import { ElMessage } from 'element-plus'
import { HttpService } from '@/services/http.service'
import { UserService } from '@/services/user.service'
import { SoftService } from '@/services/soft.service'
import { LocalStorageService } from '@/services/local-storage.service'
import { getApiBaseUrl } from '@/common/env/app-url'

const loginHttpService = new HttpService({
  baseURL: getApiBaseUrl(),
}, [{
  errorCode: [errorKeyMap.AUTH_EXPIRED.ret, errorKeyMap.NEED_LOGIN.ret],
  handler () {
    router.replace('/login')
  }
}, {
  errorCode: errorCodeList,
  handler (ret: string | number, msg: any) {
    msg = errorCodeMap[ret]?.msg || msg
    ElMessage({
      showClose: true,
      message: msg,
      type: 'error',
    })
  }
}])

export const rootInjector = new ReflectiveInjector(new NullInjector(), [
  LocalStorageService,
  SoftService,
  {
    provide: HttpService,
    useValue: loginHttpService
  },
  {
    provide: routerInjectToken,
    useValue: router
  },
  {
    provide: UserService,
  },
])
