<template>
  <router-view/>
</template>

<style lang="scss">

@import '@/assets/scss/reset.scss';

html, body {
  margin: 0;
  padding: 0;
  background: #fff;
}

</style>
