import { createApp } from 'vue'
import { reflectiveInjectorPlugin } from '@tanbo/vue-di-plugin'

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import 'jsoneditor/dist/jsoneditor.min.css'

import { rootInjector } from '@/root-injector'

import App from './App.vue'
import router from './router'

createApp(App)
  .use(router)
  .use(reflectiveInjectorPlugin, rootInjector)
  .use(ElementPlus, { size: 'small', locale })
  .mount('#app')
