import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@tanbo/vue-di-plugin'
import { HttpService } from '@/services/http.service'
import { LocalStorageService } from '@/services/local-storage.service'

export interface channelSoftInfosTY {
  softid: number
  softname: string
}

export interface ChannelRequestDataTY {
  data?: channelSoftInfosTY[]
}

@Injectable()
export class SoftService {
  channelSoftInfos = new BehaviorSubject<channelSoftInfosTY[]>([])

  constructor(private http: HttpService,
              private localStorageService: LocalStorageService) {
    this.updateChannelSoftInfos()
  }

  public updateChannelSoftInfos () {
    if (!this.localStorageService.getUserToken()) return
    this.http.post<ChannelRequestDataTY>('/get_channel_soft_infos.json', {}).then((response) => {
      this.channelSoftInfos.next(response.data)
    })
  }
}
