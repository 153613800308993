import { InjectionToken } from '@tanbo/di'
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/checkData',
    name: 'checkData',
    component: () => import(/* webpackChunkName: "checkData" */ '../views/checkData/checkData.vue')
  },
  {
    path: '/settlement',
    name: 'settlement',
    component: () => import(/* webpackChunkName: "settlement" */ '../views/settlement/settlement.vue')
  },
  {
    path: '/secondary',
    name: 'secondary',
    component: () => import(/* webpackChunkName: "secondary" */ '../views/secondary/secondary.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */ '../views/apply/apply.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export const routerInjectToken = new InjectionToken<Router>('router-token')

export default router
