import { BehaviorSubject, Observable } from 'rxjs'
import { Injectable } from '@tanbo/vue-di-plugin'
import { HttpService } from '@/services/http.service'
import { LocalStorageService } from '@/services/local-storage.service'

export interface UserInfo {
  username?: string
}

let lastUserInfo: UserInfo

try {
  lastUserInfo = JSON.parse(localStorage.getItem('userNameLocal'))
} catch (e) {
  lastUserInfo = {
    username: ''
  }
}

@Injectable()
export class UserService {
  userInfo: Observable<Partial<UserInfo> | null>
  private _userInfo: Partial<UserInfo> | null = lastUserInfo

  private changeEvent = new BehaviorSubject<Partial<UserInfo> | null>(this._userInfo)

  constructor (private http: HttpService,
               private localStorageService: LocalStorageService) {
    this.userInfo = this.changeEvent.asObservable()
  }

  updateUserinfo(userinfo: Partial<UserInfo>) {
    this._userInfo = Object.assign({}, this._userInfo, userinfo)
    this.localStorageService.setUserName(this._userInfo)
    this.changeEvent.next(this._userInfo)
  }

  logout (params: UserInfo) {
    return this.http.post('/accounts/logout_by_token/', params).then((response) => {
      return response
    })
  }
}
