import { Injectable } from '@tanbo/vue-di-plugin'

/** 用户唯一标识 */
export const Token = 'token'

/** 用户信息 */
export const UserNameLocal = 'userNameLocal'

/** 验证码标识 */
export const UserSionid = 'usersionid'

@Injectable()
export class LocalStorageService {
  setUserToken (data: string) {
    localStorage.setItem(Token, data)
  }

  getUserToken (): string {
    return localStorage.getItem(Token)
  }

  setUserName (data: any) {
    localStorage.setItem(UserNameLocal, JSON.stringify(data))
  }

  getUserName (): any {
    return this.getStorageParse(localStorage.getItem(UserNameLocal))
  }

  removeUserName () {
    localStorage.removeItem(UserNameLocal)
  }

  setUserUsessionid (data: any) {
    localStorage.setItem(UserSionid, JSON.stringify(data))
  }

  getUserUsessionid (): any {
    return this.getStorageParse(localStorage.getItem(UserSionid))
  }

  /** 获取storage parse数据 */
  private getStorageParse (string: any = '{}'): any {
    try {
      return JSON.parse(string)
    } catch (error) {
      console.error(error)
      return {}
    }
  }
}
