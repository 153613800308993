import { ResponseError } from '@/services/http.service'
import { ElMessage } from 'element-plus'

const ERROR_KEY_MAP: { [key: string]: { ret: number, msg: string } } = {
  AUTH_EXPIRED: {
    ret: 4001002,
    msg: '授权已过期，请重新登录刷新页面'
  },
  NEED_LOGIN: {
    ret: 4001004,
    msg: '需登录后才能继续操作，请登录'
  },
  REQUEST_EXPIRED: {
    ret: 4001005,
    msg: '过期请求，请刷新页面'
  },
  FORBIDDEN: {
    ret: 4001006,
    msg: '无有效授权，禁止访问，请检查您访问的资源或您的权限'
  }
}

export const errorKeyMap = ERROR_KEY_MAP

export const errorCodeMap = Object.fromEntries(Object.entries(ERROR_KEY_MAP).map(([errorKey, errorItem]) => {
  return [errorItem.ret, {
    key: errorKey,
    msg: errorItem.msg
  }]
}))

export const errorCodeList: number[] = Object.keys(ERROR_KEY_MAP).map(key => {
  return ERROR_KEY_MAP[key].ret
})

export const defaultErrorAction = function (error: ResponseError) {
  const err = errorCodeMap[error.responseBody?.ret]
  ElMessage({
    showClose: true,
    message: err?.msg || error.responseBody?.msg,
    type: 'error',
  })
}
