export const Host = window.location.host

const ApiBaseUrlMap = {
  /** 测试环境 */
  test: 'http://134.175.13.249:88/vip/',
  /** 正式环境 */
  release: 'http://u.duba.com/vip/'
}

/** 各环境的接口域名 */
export const HostApiBaseUrlMap = {
  /** 测试环境 */
  'localhost:8080': ApiBaseUrlMap.test,
  /** 测试环境 */
  '10.12.196.80:8080': ApiBaseUrlMap.test,
  /** 测试环境 */
  '134.175.13.249:88': ApiBaseUrlMap.test,
  /** 测试环境 */
  '10.12.196.80:3017': ApiBaseUrlMap.test,
  /** 正式环境 */
  'u.duba.com': ApiBaseUrlMap.release
}

export function getApiBaseUrl (): string {
  return (HostApiBaseUrlMap as any)[Host] || ApiBaseUrlMap.release
}
